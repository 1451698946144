import styled from "styled-components";


//#region Styles
const Container = styled.div`
`;
const ImageDiv = styled.div`
  background-color:#dbdbdb ;
`;
const InformationDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.divType === "Why" ? "column" : "row")};
  justify-content: ${(props) => (props.divType === "Why" ? "center" : "space-between")};
  align-items:${(props) => (props.divType === "Why" ? "center" : "flex-start")};
  padding: 2rem;
  text-align: ${(props) => (props.divType === "Why" ? "center" : "start")};
   
  font-family: "Raleway", sans-serif;
  background-color: ${(props) => (props.divType === "Why" ? "#f2f2f2" : "#ffffff")};
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & p#p1 {
    font-size: 16px;
    font-weight: 600;
  }
  & h1 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
    
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
const PicturesDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center ;
  background-color:#aa182c !important ;
  padding:1rem ;
`;
const Pictures = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  & img {
    margin:1rem ;
    width:28% ;
    @media only screen and (max-width: 768px) {
        margin:1rem ;
        width:95% ;
  }
  }
`;

//#endregion
function SolutionsContent() {
    const headerPhoto = "./catalog/Banners/solutions1.jpg";
    const firstDivPhoto = "./catalog/Banners/solutions3.jpg";
    const solutionspictures1 = "./catalog/Banners/solutionspictures1.jpg";
    const solutionspictures2 = "./catalog/Banners/solutionspictures2.jpg";
    const solutionspictures3 = "./catalog/Banners/solutionspictures3.jpg";

  const headerFirstDiv =
    "DRAGON LONDON BEST FOODS & SERVICES UNDER THE SAME ROOF";
  const pharagraph1FirstDiv =
    "Are you looking for the best quality products with perfect services for your shop?";
  const pharagraph2FirstDiv =
    "You are in the right place; At Dragon London, we provide you with the best products. We also help you and your business manage the woods and optimise the value of your products with the best services.";
  const pharagraph3FirstDiv =
    "We guarantee that you will find it all in our one-stop-shop.  ";
  const pharagraph4FirstDiv =
    "The best Polish foods in the market in your shop.";
  const pharagraph5FirstDiv =
    "Our vans are filled with the best products and delivered straight to your door but our services do not end here. We also help you to organise and optimise the order based on your needs.";
  const pharagraph6FirstDiv =
    "Stack your shelves with a variety of the highest quality dairy products and process meats. We strive to deliver the best services, always willing to help you to archive the best.";
  const pharagraph7FirstDiv =
    "Our success is based on your success. Enjoy the best food services. We guarantee 100% satisfaction!";
  
    const pharagraph1DivContent =
    "Local deliveries are made by our fleet of refrigerated vehicles with experienced and trained drivers. We pay special attention to the expiry dates and space where dairy products or meat can be stored in appropriate conditions to save freshness. That’s why having cold storage in all our vehicles is crucial. For our customers, convenience goods may be booked in advance to ensure complete orders. We also make direct orders while our store visits.";
  const pharagraph2DivContent =
    "We also offer assistance in preparing special packs for customers new to Polish food so that only optimum product lines are chosen. Our staff is trained to make sure the best products are sold to you and this ensures that if your shops wish to venture into the Polish food market can attract new customers and maximise your business with the best profits.";

  return (
    <Container>
        <ImageDiv>
        <Image
              alt=""
              loading="lazy"
              title="homepagebanner"
              src={headerPhoto} />
      </ImageDiv>
      <InformationDiv>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={firstDivPhoto} />
            </ImageDiv>
        </DivContent>
        <DivContent>
          <h1>{headerFirstDiv}</h1><p>&nbsp;</p>
          <p>{pharagraph1FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph2FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph3FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph4FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph5FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph6FirstDiv}</p><p>&nbsp;</p>
          <p>{pharagraph7FirstDiv}</p><p>&nbsp;</p>
        </DivContent>
      </InformationDiv>
      <PicturesDiv>
        <Pictures>
          <Image src={solutionspictures1} alt="market" />
          <Image src={solutionspictures2} alt="market" />
          <Image src={solutionspictures3} alt="market" />
        </Pictures>
      </PicturesDiv>
      <InformationDiv divType={"Why"}>
        <DivContent>
          <h1>WHY US?</h1>
        </DivContent>
        <DivContent>
          <p id="p1">We guarantee deliveries directly to your shop.</p>
          <p>{pharagraph1DivContent}</p><p>&nbsp;</p>
          <p>{pharagraph2DivContent}</p>
        </DivContent>
      </InformationDiv>
    </Container>
  );
}

export default SolutionsContent;