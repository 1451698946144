
import styled from "styled-components";

//#region Styles

const Container = styled.div`
`;
const ImageDiv = styled.div`
  background-color:#dbdbdb ;
`;
const InformationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #fff ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #818181;
    font-weight: 400;
  }
  & h1 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
    
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
//#endregion
function AboutContent() {
  return (
    <Container>
      <ImageDiv>
        <Image
              alt=""
              loading="lazy"
              title="homepagebanner"
              src={`./catalog/Banners/dragonlondonltdheader2.jpg`} />
      </ImageDiv>
      <InformationDiv>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontentAbout1.jpg`} />
            </ImageDiv>
        </DivContent>
        <DivContent>
          <h1> WE ARE WORLD FOOD SPECIALISTS </h1><p>&nbsp;</p>
          <p>At Dragon London we have one mission:</p><p>&nbsp;</p>
          <p>We deliver the best products in the market and take care of the orders to keep your shop full for you.</p><p>&nbsp;</p>
          <p>Our team is strategically trained to help you set up our products in your store to optimise the value of your business. We’ll improve your shop’s capability to ensure the products display correctly and are sold right away.</p><p>&nbsp;</p>
          <p>We use the best quality transport; Our vans are always filled with the best products to ensure they arrive safely and on time to your shop. </p><p>&nbsp;</p>
          <p>We have also trained all our sales team to visit your shops using the latest technology to take orders and bring the goods directly to your shelves. </p><p>&nbsp;</p>
          <p>This service is designed for faster implementation of planned purchases and greater efficiency in your business. </p>
        </DivContent>
      </InformationDiv>
      <InformationDiv>
        
        <DivContent>
          <h1> HELPING YOUR BUSINESS TO THRIVE </h1><p>&nbsp;</p>
          <p>We take care of the highest quality of products sold. For food, we offer branded products repeatedly inspected and checked in origin. We guarantee that meats, dairy  and other items available in our warehouse come from trusted suppliers.</p><p>&nbsp;</p>
          <p>Constantly we make sure that our offer is attractive in terms of price and product. </p><p>&nbsp;</p>
          <p>We cooperate with Polish manufacturers making sure we provide the highest quality products straight to your shelves. </p><p>&nbsp;</p>
        </DivContent>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontentAbout2.jpg`} />
            </ImageDiv>
        </DivContent>
      </InformationDiv>
    </Container>
  );
}

export default AboutContent;
