
import styled from "styled-components";
import useForm from "../../utilities/hooks/useForm";
import {validateInfo} from '../../utilities/helpers';

//#region Styles

const Container = styled.div`

`;
const ImageDiv = styled.div`
  background-color:#fff ;
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
  
`;
const DivContent = styled.div`
  width: 50%;
  margin: 1rem;
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;
const ContactUsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #fff ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const ContactMapDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem;
 
  font-family: "Raleway", sans-serif;
  background-color: #fff ;
  & p {
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    line-height: 26px;
    color: #818181;
    font-weight: 400;
  }
  & h2 {
    color: #ce0e2d;
    font-family: 'Montserrat', sans-serif;
    font-size: 34px;
    font-weight: 800;
    text-transform: capitalize;
  }
  & h4 {
    color: #ffffff;
    font-size: 15px;
    line-height: 1.58em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  border-color: lightgray;
  border-style: solid;
  &:focus {
    outline: none !important;
    border-color: white;
  }
`;
const MultiInput = styled.textarea`
  margin: 10px 0;
  padding: 10px;
  border: 1px;
  height: 15vw;
  border-color: lightgray;
  border-style: solid;
  text-align: start;
`;
const Button = styled.input`
  color: #aa182c;
  font-size: 15px;
  font-weight: 600;
  border: none;
  padding: 15px 20px;
  background-color: #f7f7f7;
  opacity: 80%;
  margin-bottom: 10px;
`;

//#endregion
function HomePageInformationContent(props) {
  const { handleChange, handleSubmit, values, errors } = useForm(
    props.sendMail,
    validateInfo
  );
  return (
    <Container>
      <ContactUsDiv>
        <DivContent>
          <h2> WELCOME TO DRAGON LONDON</h2><p>&nbsp;</p>
          <p>We are a full-service focus. We’ll deliver you the best goods in the market and offer the best services. Our team is strategically trained to help you set up our products in your store to optimise the value of your business.</p>
          <p>&nbsp;</p>
          <p>We’ll improve your shop’s capability to ensure the products display correctly and are sold right away.</p>
          <p>&nbsp;</p>
          <p>We also help you with the maintenance of the products. Our team will take care of the orders keeping your shop full for you.</p>
          <p>&nbsp;</p>
          <p>We understand the market and your business; we have twenty plus years of experience in the food industry. Our team is here to advise you and to help your business to succeed. </p>
        </DivContent>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontenthomepage1.jpg`} />
            </ImageDiv>
        </DivContent>
      </ContactUsDiv>
      <ContactMapDiv>
        <DivContent>
            <ImageDiv>
                <Image
                    alt=""
                    loading="lazy"
                    title="homepagebanner"
                    src={`./catalog/Banners/dragonlondonltdcontenthomepage2.jpg`} />
            </ImageDiv>
        </DivContent>
        <DivContent>
          <h2> LEADERS IN POLISH FOODS ON THE BRITISH MARKET </h2><p>&nbsp;</p>
          <p>Our distribution centres are placed in strategic locations to make sure the customers are served on time with suitable and sufficient quality of delivery.</p><p>&nbsp;</p>
          <p>We use the best quality transport; Our vans are always filled with the best products to ensure they arrive safely and on time to your shop.</p><p>&nbsp;</p>
          <p>We have also trained all our sales team to visit your shops using the latest technology to take orders and bring the goods directly to your shelves.</p><p>&nbsp;</p>
          <p>This service is designed for faster implementation of planned purchases and greater efficiency in your business. </p><p>&nbsp;</p>
          <p>We are also ready to receive a website order. Get in touch here. </p>
        </DivContent>
      </ContactMapDiv>
    </Container>
  );
}

export default HomePageInformationContent;
