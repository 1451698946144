import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageSlider from "../components/ImageSlider";
import MenuBar from "../components/MenuComponents/MenuBar";
import Carousel from "../components/Carousel";
import BrandsCarousel from "../components/BrandsCarousel";
import useWindowWidthAndHeight from "../utilities/hooks/useWindowWidthAndHeight";
import HomePageInformationContent from "../components/PagesComponents/HomePageInformationContent";
const Container = styled.div`
  min-height: ${(props) => props.height}px;
  min-width: ${(props) =>  props.width >1780 ? "1780px" : `${props.width}px`};
`;
const Home = () => {
  const { height,width} = useWindowWidthAndHeight();
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  },[])
  return (
    <Container height={height} width={width}>
      <Header />      
      <ImageSlider type={"home"} />
      <HomePageInformationContent/>
      
      <BrandsCarousel />
      <Footer />
    </Container>
  );
};
export default Home;
